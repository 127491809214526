import { Map, List } from 'immutable';
import IImmutableGetAllAdGroupsReducer from './interfaces/immutable/iImmutableGetAllAdGroupsReducer';
import GetAllAdGroupsActionNames from '../constants/getAllAdGroupsActionNames';

const initialState = Map({
  allGroups: List(),
  loading: false,
  loadingOffices: false,
  errors: List(),
  offices: List(),
}) as IImmutableGetAllAdGroupsReducer;

const getAllAdGroupsReducer = (
  state: IImmutableGetAllAdGroupsReducer = initialState,
  action: any
) => {
  switch (action.type) {
    case GetAllAdGroupsActionNames.GET_ALL_AD_GROUPS: {
      return state.withMutations((state) => {
        state.set('loading', true);
      });
    }
    case GetAllAdGroupsActionNames.GET_ALL_AD_GROUPS_SUCCESS: {
      return state.withMutations((state) => {
        state.set('loading', false);
        state.set('allGroups', List(action.payload));
      });
    }
    case GetAllAdGroupsActionNames.GET_ALL_AD_GROUPS_ERROR: {
      return state.withMutations((state) => {
        state.set('loading', false);
        state.update('errors', (errors) =>
          errors.push({
            error: action.payload,
            time: new Date(),
          })
        );
      });
    }
    case GetAllAdGroupsActionNames.GET_OFFICES: {
      return state.set('loadingOffices', true);
    }
    case GetAllAdGroupsActionNames.GET_OFFICES_SUCCESS: {
      return state.withMutations((state) => {
        state.set('loadingOffices', false);
        state.set('offices', List(action.payload));
      });
    }
    case GetAllAdGroupsActionNames.GET_OFFICES_ERROR: {
      return state.withMutations((state) => {
        state.set('loadingLocation', false);
        state.update('errors', (errors) =>
          errors.push({
            error: action.payload,
            time: new Date(),
          })
        );
      });
    }

    default: {
      return state;
    }
  }
};
export default getAllAdGroupsReducer;
