import React, { Component } from "react";
import ToasterActionCreators from "../actions/toasterActions";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import NavigationBar from "./NavigationBar";
import FontAwesomeIcon from "../common/fontAwesomeIcons";
import { Row, Col } from "../common/ReactBootstrapManager";
import { Container, Form, Button } from "../common/ReactBootstrapManager";
import CreatableSelect from "../common/CreatableSelect";
import Select from "../common/ReactSelect";
import IApplicationState from "../setup/IApplicationState";
import LoginActionCreators from "../actions/loginActions";
import GenerateUsernamesActionCreators from "../actions/generateUsernamesActions";
import GenerateAdUsersActionCreators from "../actions/generateAdUsersActions";
import GetAllAdGroupsActionCreators from "../actions/getAllAdGroupsActions";
import GetIssueIdOrKeyActionCreators from "../actions/getIssueIdOrKeyActions";
import UsernameDto from "../models/usernameDto";
import ContractType from "../models/contractTypes";
import GenerateUsernamesSelectors from "../selectors/generateUsernamesSelector";
import GenerateADUserSelectors from "../selectors/generateAdUsersSelector";
import GetAllAdGroupsSelectors from "../selectors/getAllAdGroupsSelector";
import GetIssueIdOrKeySelectors from "../selectors/getIssueIdOrKeySelector";
import { withRouter, RouteComponentProps } from "react-router";
import UrlUtils from "../lib/urlUtils";
import LoadingWrapper from "../common/loadingWrapper";
import FormCheck from "react-bootstrap/FormCheck";
import { List } from "immutable";
import OfficeDto from "../models/officeInfoDto";

interface IDashboardProps {
  toasterDashMessage: (message: string, variant: string) => any;
  generateUsernames: (user: UsernameDto) => any;
  generateAdUsers: (group: UsernameDto) => any;
  getAllAdGroups: () => any;
  getJiraTaskMetadata: (issueIdOrKey: string) => void;
  getOffices: () => any;
  usernames: Array<string>;
  allGroups: Array<string>;
  jiraMetadata: UsernameDto;
  loading: boolean;
  loadingUsername: boolean;
  loadingAdUser: boolean;
  offices: List<OfficeDto>;
}

interface IDashboardState {
  user: UsernameDto;
  contract: ContractType;
  jiraMetadata: UsernameDto;
  validated: boolean;
  validated1: boolean;
}

interface IData {
  [key: string]: any;
}

type IDashboardWithRouterProps = IDashboardProps & RouteComponentProps<{}>;

const ATTRIBUTES = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  CONTRACT_TYPE: "contractType",
  PHONE_NUMBER: "phoneNumber",
  OFFICE: "office",
  COMPANY: "company",
  COUNTRYCODE: "countryCode",
  STREET: "street",
  CITY: "city",
  ZIP: "zip",
  ADGROUPS: "adGroups",
  EMAIL: "email",
  DISPLAY_NAME: "displayName",
  IS_EXTERNAL: "isExternal",
};

class Dashboard extends Component<IDashboardWithRouterProps, IDashboardState> {
  state = {
    user: new UsernameDto(),
    contract: new ContractType(),
    jiraMetadata: new UsernameDto(),
    validated: false,
    validated1: false,
  };
  constructor(props: IDashboardWithRouterProps) {
    super(props);

    this.handleChanges = this.handleChanges.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitUsername = this.handleSubmitUsername.bind(this);
    this.handleSubmitADUsers = this.handleSubmitADUsers.bind(this);
    this.handleChangesLDAPUserAdGroups =
      this.handleChangesLDAPUserAdGroups.bind(this);
    this.handleChangesLDAPUser = this.handleChangesLDAPUser.bind(this);
    this.handleChangesLDAPUserInputs =
      this.handleChangesLDAPUserInputs.bind(this);
  }

  componentDidMount() {
    const { getAllAdGroups, getJiraTaskMetadata, location, getOffices } =
      this.props;
    getAllAdGroups();
    getOffices();

    const params = UrlUtils.getParams(location.search);
    if (params.jiraKey) {
      getJiraTaskMetadata(params.jiraKey);
    }
  }

  componentDidUpdate(prevProps: IDashboardProps) {
    const {
      loading,
      generateUsernames,
      jiraMetadata,
      loadingUsername,
      usernames,
    } = this.props;

    if (prevProps.loading && !loading) {
      generateUsernames(jiraMetadata);
      const newJiraMetadata = new UsernameDto(this.state.jiraMetadata);
      newJiraMetadata.firstName = jiraMetadata.firstName;
      newJiraMetadata.middleName = jiraMetadata.middleName;
      newJiraMetadata.lastName = jiraMetadata.lastName;
      newJiraMetadata.userName = jiraMetadata.userName;
      newJiraMetadata.contractType = jiraMetadata.contractType;
      newJiraMetadata.phoneNumber = jiraMetadata.phoneNumber;
      newJiraMetadata.email = newJiraMetadata.generateEmail();
      newJiraMetadata.displayName = newJiraMetadata.generateDisplayName();

      this.setState({ jiraMetadata: newJiraMetadata });
    }
    if (prevProps.loadingUsername && !loadingUsername) {
      const username = usernames[0];
      const newJiraMetadata = new UsernameDto(this.state.jiraMetadata);
      newJiraMetadata.userName = jiraMetadata.userName || username;

      this.setState({ jiraMetadata: newJiraMetadata });
    }
  }

  handleChanges(propName: string, value: string) {
    const { jiraMetadata } = this.state;

    let newUser = new UsernameDto(this.state.jiraMetadata);
    (newUser as IData)[propName] = value;
    if (jiraMetadata.isExternal === false) {
      jiraMetadata.email = this.generateEmail(newUser);
    }
    jiraMetadata.displayName = this.generateDisplayName(newUser);
    jiraMetadata.firstName = this.getFirstName(newUser);
    jiraMetadata.middleName = this.getMiddleName(newUser);
    jiraMetadata.lastName = this.getLastName(newUser);
    if (jiraMetadata.isExternal === true) {
      jiraMetadata.userName = jiraMetadata.email.split("@")[0];
    }

    this.setState({ user: newUser });
  }
  generateEmail(user: UsernameDto): string {
    const { firstName, lastName, middleName } = user;
    const replaceSpecialChars = (str: string) => {
      return str
        .toLocaleLowerCase()
        .replace(/æ/g, "ae")
        .replace(/ø/g, "oe")
        .replace(/å/g, "aa");
    };

    const firstNameProcessed = replaceSpecialChars(firstName);
    const lastNameProcessed = replaceSpecialChars(lastName);
    const middleNameProcessed = middleName
      ? replaceSpecialChars(middleName)
      : "";

    let email;
    if (middleNameProcessed === "") {
      email = `${firstNameProcessed}.${lastNameProcessed}@computas.com`;
    } else {
      email = `${firstNameProcessed}.${middleNameProcessed}.${lastNameProcessed}@computas.com`;
    }
    return email.replace(/ /g, "");
  }

  generateDisplayName(jiraMetadata: UsernameDto): string {
    const { firstName, lastName, middleName } = jiraMetadata;
    if (middleName === undefined || middleName === null || middleName === "") {
      const displayName = `${firstName} ${lastName}`;
      return displayName;
    } else {
      const displayName = `${firstName} ${middleName} ${lastName}`;
      return displayName;
    }
  }

  handleChangesLDAPUser(propName: string, value: any) {
    const { jiraMetadata } = this.state;

    let newLDapUser = new UsernameDto(jiraMetadata);
    if (!value) {
      newLDapUser.office = "";
    } else {
      (newLDapUser as IData)[propName] = value.value;

      if (propName === "office") {
        const office = this.props.offices.find(
          (o) => o.name === (newLDapUser as IData)["office"]
        );
        if (office) {
          (newLDapUser as IData)["street"] = office.street;
          (newLDapUser as IData)["zip"] = office.zip;
          (newLDapUser as IData)["city"] = office.city;
          (newLDapUser as IData)["countryCode"] = office.countryCode;
        }
      }
    }

    this.setState({ jiraMetadata: newLDapUser });
  }

  handleChangesLDAPUserInputs(propName: string, value: any) {
    const { jiraMetadata } = this.state;

    let newLDapUser = new UsernameDto(jiraMetadata);
    (newLDapUser as IData)[propName] = value;

    this.setState({ jiraMetadata: newLDapUser });
  }

  handleChangesLDAPUserAdGroups(propName: string, values: Array<any> | null) {
    const { jiraMetadata } = this.state;

    let newLDapUser = new UsernameDto(jiraMetadata);
    if (!values) {
      newLDapUser.adGroups = [];
    } else {
      const lastValue = (values as Array<any>)[values.length - 1].value;
      newLDapUser.adGroups.push(lastValue);
    }

    this.setState({ jiraMetadata: newLDapUser });
  }

  handleChange(propName: string, value: ContractType) {
    const { contract } = this.state;

    let newContract = new ContractType(contract);
    (newContract as IData)[propName] = value;

    this.setState({ contract: value });
  }

  onChangeLDAPAllGroups(propName: string, value: UsernameDto) {
    const { jiraMetadata } = this.state;

    let newLDapUser = new UsernameDto(jiraMetadata);
    (newLDapUser as IData)[propName] = value;

    this.setState({ jiraMetadata: value });
  }

  getFirstName(user: UsernameDto): string {
    const { firstName } = user;
    const firstNames = `${firstName}`;
    return firstNames;
  }

  getMiddleName(user: UsernameDto): string {
    const { middleName } = user;
    const middleNames = `${middleName}`;
    return middleNames;
  }

  getLastName(user: UsernameDto): string {
    const { lastName } = user;
    const lastNames = `${lastName}`;
    return lastNames;
  }

  handleSubmitUsername() {
    const { user, contract } = this.state;
    const { generateUsernames } = this.props;

    if (this.validateForm()) {
      let newUser = new UsernameDto(user);
      newUser.contractType = contract.value;
      generateUsernames(newUser);
    } else {
      this.setState({ validated: true });
    }
  }

  handleSubmitADUsers() {
    const { generateAdUsers } = this.props;
    const { jiraMetadata } = this.state;

    if (this.validateForm1()) {
      let newUser = new UsernameDto(jiraMetadata);
      if (jiraMetadata.isExternal === true) {
        newUser.userName = this.state.jiraMetadata.email.split("@")[0];
        generateAdUsers(newUser);
      } else {
        generateAdUsers(newUser);
      }
    } else {
      this.setState({ validated1: true });
    }
  }

  validateForm() {
    return !(
      this.validateFormElement(ATTRIBUTES.FIRST_NAME, false) ||
      this.validateFormElement(ATTRIBUTES.LAST_NAME, false) ||
      this.validateFormElement(ATTRIBUTES.CONTRACT_TYPE, false)
    );
  }

  validateForm1() {
    const { location } = this.props;
    const params = UrlUtils.getParams(location.search);
    if (params && params.jiraKey) {
      return !(
        this.validateFormElement1(ATTRIBUTES.OFFICE, false) ||
        this.validateFormElement1(ATTRIBUTES.COMPANY, false) ||
        this.validateFormElement1(ATTRIBUTES.EMAIL, false) ||
        this.validateFormElement1(ATTRIBUTES.COUNTRYCODE, false) ||
        this.validateFormElement1(ATTRIBUTES.STREET, false) ||
        this.validateFormElement1(ATTRIBUTES.CITY, false) ||
        this.validateFormElement1(ATTRIBUTES.ZIP, false)
      );
    } else {
      return !(
        this.validateFormElement1(ATTRIBUTES.PHONE_NUMBER, false) ||
        this.validateFormElement1(ATTRIBUTES.OFFICE, false) ||
        this.validateFormElement1(ATTRIBUTES.EMAIL, false) ||
        this.validateFormElement1(ATTRIBUTES.COMPANY, false) ||
        this.validateFormElement1(ATTRIBUTES.COUNTRYCODE, false) ||
        this.validateFormElement1(ATTRIBUTES.STREET, false) ||
        this.validateFormElement1(ATTRIBUTES.CITY, false) ||
        this.validateFormElement1(ATTRIBUTES.ZIP, false)
      );
    }
  }

  validateFormElement(
    attribute: string,
    checkValidated: boolean = true
  ): boolean {
    const { validated, jiraMetadata, contract } = this.state;

    if (!validated && checkValidated) {
      return false;
    }

    switch (attribute) {
      case ATTRIBUTES.FIRST_NAME:
        return !jiraMetadata.firstName;

      case ATTRIBUTES.LAST_NAME:
        return !jiraMetadata.lastName;

      case ATTRIBUTES.CONTRACT_TYPE:
        return !(contract && contract.value);

      default:
        return false;
    }
  }

  validateFormElement1(
    attribute: string,
    checkValidated1: boolean = true
  ): boolean {
    const { validated1, jiraMetadata } = this.state;
    if (!validated1 && checkValidated1) {
      return false;
    }

    switch (attribute) {
      case ATTRIBUTES.EMAIL:
        return (
          !jiraMetadata.isAsciiString(jiraMetadata.email) ||
          jiraMetadata.email === ""
        );

      case ATTRIBUTES.PHONE_NUMBER:
        return !jiraMetadata.phoneNumber;

      case ATTRIBUTES.OFFICE:
        return !jiraMetadata.office;

      case ATTRIBUTES.COUNTRYCODE:
        return !jiraMetadata.countryCode;

      case ATTRIBUTES.COMPANY:
        return !jiraMetadata.company;

      case ATTRIBUTES.STREET:
        return !jiraMetadata.street;

      case ATTRIBUTES.CITY:
        return !jiraMetadata.city;

      case ATTRIBUTES.ZIP:
        return !jiraMetadata.zip;

      default:
        return false;
    }
  }

  render() {
    const {
      usernames,
      allGroups,
      offices,
      location,
      loading,
      loadingUsername,
      loadingAdUser,
    } = this.props;
    const { contract, jiraMetadata } = this.state;
    const params = UrlUtils.getParams(location.search);
    const contractTypes = [
      { value: "Permanent contract", label: "Permanent contract" },
      { value: "Temporary contract", label: "Temporary contract" },
    ];

    return (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={12}>
            <NavigationBar />
          </Col>
        </Row>
        <br />
        <br />
        <Container fluid className="mt-5">
          {params && params.jiraKey ? (
            <LoadingWrapper loading={loading} className="wrapperDashboard mb-1">
              <Form className="w-100 h-100">
                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    First Name
                  </Form.Label>
                  <Col lg={3} sm={4}>
                    <Form.Control
                      readOnly
                      type="text"
                      id="firstName"
                      autoComplete="off"
                      value={jiraMetadata.firstName}
                      onChange={(e: any) =>
                        this.handleChanges("firstName", e.target.value)
                      }
                      maxLength={50}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Last Name
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      readOnly
                      name="lastName"
                      type="string"
                      maxLength={50}
                      value={jiraMetadata.lastName}
                      onChange={(e: any) =>
                        this.handleChanges("lastName", e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    User contract type
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Select
                      name="contractType"
                      placeholder="Select contract type"
                      options={jiraMetadata}
                      value={{
                        label: jiraMetadata.contractType,
                        value: jiraMetadata.contractType,
                      }}
                      onChange={this.handleChange}
                      isClearable
                      isDisabled
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Username
                  </Form.Label>
                  <Col md={4} lg={3}>
                    <CreatableSelect
                      name="userName"
                      placeholder="Select username"
                      value={{
                        label: jiraMetadata.userName,
                        value: jiraMetadata.userName,
                      }}
                      options={usernames.map((u) => {
                        return {
                          label: u.toLocaleLowerCase(),
                          value: u.toLocaleLowerCase(),
                        };
                      })}
                      onChange={this.handleChangesLDAPUser}
                      isClearable
                      isDisabled
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Email
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="email"
                      type="string"
                      maxLength={70}
                      value={jiraMetadata.email}
                      isInvalid={this.validateFormElement1(ATTRIBUTES.EMAIL)}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "email",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email address
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Display Name
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="displayName"
                      type="string"
                      maxLength={100}
                      value={jiraMetadata.displayName}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "displayName",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Office
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <CreatableSelect
                      name="office"
                      placeholder="Select office"
                      value={{
                        label: jiraMetadata.office,
                        value: jiraMetadata.office,
                      }}
                      options={offices.map((u) => {
                        return {
                          label: u.name,
                          value: u.name,
                        };
                      })}
                      onChange={this.handleChangesLDAPUser}
                      isClearable
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Country Code
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="countryCode"
                      type="string"
                      autoComplete="off"
                      maxLength={50}
                      value={jiraMetadata.countryCode}
                      isInvalid={this.validateFormElement1(
                        ATTRIBUTES.COUNTRYCODE
                      )}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "countryCode",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Country Code is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Company Name
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="companyName"
                      type="string"
                      autoComplete="off"
                      maxLength={50}
                      value={jiraMetadata.company}
                      isInvalid={this.validateFormElement1(ATTRIBUTES.COMPANY)}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "company",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Company is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Mobile
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="phone"
                      type="string"
                      maxLength={50}
                      value={jiraMetadata.phoneNumber}
                      readOnly
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "phoneNumber",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Street
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="street"
                      type="string"
                      autoComplete="off"
                      maxLength={100}
                      value={jiraMetadata.street}
                      isInvalid={this.validateFormElement1(ATTRIBUTES.STREET)}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "street",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Street is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    City
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="city"
                      type="string"
                      autoComplete="off"
                      maxLength={50}
                      value={jiraMetadata.city}
                      isInvalid={this.validateFormElement1(ATTRIBUTES.CITY)}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs("city", e.target.value)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      City is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Zip
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="zip"
                      type="string"
                      autoComplete="off"
                      maxLength={50}
                      value={jiraMetadata.zip}
                      isInvalid={this.validateFormElement1(ATTRIBUTES.ZIP)}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs("zip", e.target.value)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Zip is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    AD groups
                  </Form.Label>
                  <Col md={4} lg={3}>
                    <CreatableSelect
                      name="adGroups"
                      options={allGroups.map((u) => {
                        return { label: u, value: u };
                      })}
                      value={jiraMetadata.adGroups.map((u) => {
                        return { label: u, value: u };
                      })}
                      isMulti
                      onChange={this.handleChangesLDAPUserAdGroups}
                    />
                  </Col>
                </Form.Group>

                <br />
                <br />
                <LoadingWrapper
                  loading={loadingAdUser}
                  className="wrapperDashboard mb-1"
                >
                  <Form.Group
                    as={Row}
                    className="justify-content-center align-content-center"
                  >
                    <Col sm={2} md={2} lg={2}>
                      <Button
                        variant="success"
                        onClick={this.handleSubmitADUsers}
                      >
                        <FontAwesomeIcon
                          icon="user-tag"
                          size="1x"
                          title="User generation"
                          className="mt-1 mr-2"
                          fixedWidth
                        />
                        <span>Generate AD user</span>
                      </Button>
                    </Col>
                  </Form.Group>
                </LoadingWrapper>
                <br />
                <br />
              </Form>
            </LoadingWrapper>
          ) : (
            <React.Fragment>
              <br />
              <br />

              <Form className="w-100 h-100 mt-3">
                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column lg={1} md={1} sm={1}>
                    User type
                  </Form.Label>
                  <Col md={4} lg={1} xs={6}>
                    <FormCheck
                      id="radioOption1"
                      type="radio"
                      name="isExternal"
                      className="p-0"
                    >
                      <Col xs={6} className="mt-1">
                        <FormCheck.Input
                          checked={jiraMetadata.isExternal === true}
                          onChange={() =>
                            this.handleChangesLDAPUserInputs("isExternal", true)
                          }
                          type="radio"
                        />

                        <FormCheck.Label>External</FormCheck.Label>
                      </Col>
                    </FormCheck>
                  </Col>
                  <Col md={2} lg={2} xs={6}>
                    <FormCheck
                      id="radioOption2"
                      type="radio"
                      name="isExternal"
                      className="p-0"
                    >
                      <Col xs={6} className="mt-1">
                        <FormCheck.Input
                          checked={jiraMetadata.isExternal === false}
                          onChange={() =>
                            this.handleChangesLDAPUserInputs(
                              "isExternal",
                              false
                            )
                          }
                          type="radio"
                        />
                        <FormCheck.Label className="mxWidthLeft">
                          Internal
                        </FormCheck.Label>
                      </Col>
                    </FormCheck>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    First Name
                  </Form.Label>
                  <Col lg={3} sm={4}>
                    <Form.Control
                      type="text"
                      id="firstName"
                      autoComplete="off"
                      value={jiraMetadata.firstName}
                      isInvalid={this.validateFormElement(
                        ATTRIBUTES.FIRST_NAME
                      )}
                      onChange={(e: any) =>
                        this.handleChanges("firstName", e.target.value)
                      }
                      maxLength={50}
                    />
                    <Form.Control.Feedback type="invalid">
                      First name is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Middle Name
                  </Form.Label>
                  <Col lg={3} sm={4}>
                    <Form.Control
                      name="middleName"
                      type="string"
                      autoComplete="off"
                      maxLength={50}
                      value={jiraMetadata.middleName}
                      onChange={(e: any) =>
                        this.handleChanges("middleName", e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Last Name
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="lastName"
                      type="string"
                      autoComplete="off"
                      maxLength={50}
                      value={jiraMetadata.lastName}
                      isInvalid={this.validateFormElement(ATTRIBUTES.LAST_NAME)}
                      onChange={(e: any) =>
                        this.handleChanges("lastName", e.target.value)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Last name is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {jiraMetadata.isExternal === false && (
                  <>
                    <Form.Group as={Row} className="justify-content-center">
                      <Form.Label column sm={1}>
                        User contract type
                      </Form.Label>
                      <Col sm={4} lg={3}>
                        <Select
                          name="contractType"
                          placeholder="Select contract type"
                          options={contractTypes}
                          value={contract}
                          onChange={this.handleChange}
                          isClearable
                          isInvalid={this.validateFormElement(
                            ATTRIBUTES.CONTRACT_TYPE
                          )}
                          error="Contract type is required!"
                        />
                      </Col>
                    </Form.Group>
                    <br />
                    <LoadingWrapper
                      loading={loadingUsername}
                      className="wrapperDashboard mb-1"
                    >
                      <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center"
                      >
                        <Col sm={2} md={2}>
                          <Button
                            type="button"
                            variant="success"
                            onClick={this.handleSubmitUsername}
                          >
                            <FontAwesomeIcon
                              icon="user"
                              size="1x"
                              title="User generation"
                              className="justify-content-center align-content-center mt-1 mr-1"
                              fixedWidth
                            />
                            <span>Generate username</span>
                          </Button>
                        </Col>
                      </Form.Group>
                    </LoadingWrapper>
                  </>
                )}
              </Form>
              <br />

              <Form>
                {jiraMetadata.isExternal === false ? (
                  <Form.Group as={Row} className="justify-content-center">
                    <Form.Label column sm={1}>
                      Username
                    </Form.Label>
                    <Col md={4} lg={3}>
                      <CreatableSelect
                        name="userName"
                        placeholder="Select username"
                        value={{
                          label: jiraMetadata.userName,
                          value: jiraMetadata.userName,
                        }}
                        options={usernames.map((u) => {
                          return {
                            label: u.toLocaleLowerCase(),
                            value: u.toLocaleLowerCase(),
                          };
                        })}
                        onChange={this.handleChangesLDAPUser}
                        isClearable
                      />
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row} className="justify-content-center">
                    <Form.Label column sm={1}>
                      Username
                    </Form.Label>
                    <Col md={4} lg={3}>
                      <Form.Control
                        name="userName"
                        type="string"
                        value={jiraMetadata.email.split("@")[0]}
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                )}
                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Email
                  </Form.Label>
                  <Col sm={4} lg={3} md={2}>
                    <Form.Control
                      name="email"
                      type="string"
                      autoComplete="off"
                      maxLength={70}
                      value={jiraMetadata.email}
                      isInvalid={this.validateFormElement1(ATTRIBUTES.EMAIL)}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "email",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email address
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Display Name
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="displayName"
                      type="string"
                      autoComplete="off"
                      maxLength={100}
                      value={jiraMetadata.displayName}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "displayName",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Form.Group>
                {jiraMetadata.isExternal === true ? (
                  <Form.Group as={Row} className="justify-content-center">
                    <Form.Label column sm={1}>
                      Office
                    </Form.Label>
                    <Col sm={4} lg={3}>
                      <Form.Control
                        name="office"
                        type="string"
                        autoComplete="off"
                        maxLength={100}
                        value={jiraMetadata.office}
                        isInvalid={this.validateFormElement1(ATTRIBUTES.OFFICE)}
                        onChange={(e: any) =>
                          this.handleChangesLDAPUserInputs(
                            "office",
                            e.target.value
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Office is required!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row} className="justify-content-center">
                    <Form.Label column sm={1}>
                      Office
                    </Form.Label>
                    <Col sm={4} lg={3}>
                      <CreatableSelect
                        name="office"
                        placeholder="Select office"
                        value={{
                          label: jiraMetadata.office,
                          value: jiraMetadata.office,
                        }}
                        options={offices.map((u) => {
                          return {
                            label: u.name,
                            value: u.name,
                          };
                        })}
                        onChange={this.handleChangesLDAPUser}
                        isClearable
                      />
                    </Col>
                  </Form.Group>
                )}
                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Company Name
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="companyName"
                      type="string"
                      autoComplete="off"
                      maxLength={50}
                      value={jiraMetadata.company}
                      isInvalid={this.validateFormElement1(ATTRIBUTES.COMPANY)}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "company",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Company is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Country Code
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="countryCode"
                      type="string"
                      autoComplete="off"
                      maxLength={50}
                      value={jiraMetadata.countryCode}
                      isInvalid={this.validateFormElement1(
                        ATTRIBUTES.COUNTRYCODE
                      )}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "countryCode",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Country Code is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Mobile
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="phone2"
                      type="string"
                      autoComplete="off"
                      value={jiraMetadata.phoneNumber}
                      isInvalid={this.validateFormElement1(
                        ATTRIBUTES.PHONE_NUMBER
                      )}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "phoneNumber",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Phone number is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Street
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="street"
                      type="string"
                      autoComplete="off"
                      maxLength={100}
                      value={jiraMetadata.street}
                      isInvalid={this.validateFormElement1(ATTRIBUTES.STREET)}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs(
                          "street",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Street is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    City
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="city"
                      type="string"
                      autoComplete="off"
                      maxLength={50}
                      value={jiraMetadata.city}
                      isInvalid={this.validateFormElement1(ATTRIBUTES.CITY)}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs("city", e.target.value)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      City is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    Zip
                  </Form.Label>
                  <Col sm={4} lg={3}>
                    <Form.Control
                      name="zip"
                      type="string"
                      autoComplete="off"
                      maxLength={50}
                      value={jiraMetadata.zip}
                      isInvalid={this.validateFormElement1(ATTRIBUTES.ZIP)}
                      onChange={(e: any) =>
                        this.handleChangesLDAPUserInputs("zip", e.target.value)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Zip is required!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="justify-content-center">
                  <Form.Label column sm={1}>
                    AD groups
                  </Form.Label>
                  <Col md={4} lg={3}>
                    <CreatableSelect
                      name="adGroups"
                      options={allGroups.map((u) => {
                        return { label: u, value: u };
                      })}
                      isMulti
                      onChange={this.handleChangesLDAPUserAdGroups}
                    />
                  </Col>
                </Form.Group>
                <br />
                <LoadingWrapper
                  loading={loadingAdUser}
                  className="wrapperDashboard mb-1"
                >
                  <Form.Group
                    as={Row}
                    className="justify-content-center align-content-center"
                  >
                    <Col sm={2} md={2} lg={2}>
                      <Button
                        type="button"
                        variant="success"
                        onClick={this.handleSubmitADUsers}
                      >
                        <FontAwesomeIcon
                          icon="user-tag"
                          size="1x"
                          title="User generation"
                          className="justify-content-center align-content-center mt-1 mr-2"
                          fixedWidth
                        />
                        <span>Generate AD user</span>
                      </Button>
                    </Col>
                  </Form.Group>
                </LoadingWrapper>
                <br />
                <br />
              </Form>
            </React.Fragment>
          )}
        </Container>
        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store: IApplicationState) => {
  return {
    usernames: GenerateUsernamesSelectors.usernames(store),
    offices: GetAllAdGroupsSelectors.offices(store),
    allGroups: GetAllAdGroupsSelectors.allGroups(store),
    jiraMetadata: GetIssueIdOrKeySelectors.jiraMetadata(store),
    loading: GetIssueIdOrKeySelectors.loading(store),
    loadingUsername: GenerateUsernamesSelectors.loading(store),
    loadingAdUser: GenerateADUserSelectors.loading(store),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  generateUsernames: (user: UsernameDto) =>
    dispatch(GenerateUsernamesActionCreators.generateUsernames(user)),
  generateAdUsers: (LDapUser: UsernameDto) =>
    dispatch(GenerateAdUsersActionCreators.generateAdUsers(LDapUser)),
  getAllAdGroups: () => dispatch(GetAllAdGroupsActionCreators.getAllAdGroups()),
  getJiraTaskMetadata: (issueIdOrKey: string) =>
    dispatch(GetIssueIdOrKeyActionCreators.getJiraTaskMetadata(issueIdOrKey)),
  logout: () => dispatch(LoginActionCreators.logout()),
  toasterDashMessage: (message: string, variant: string) =>
    dispatch(ToasterActionCreators.toasterDashMessage(message, variant)),
  getOffices: () => dispatch(GetAllAdGroupsActionCreators.getOffices()),
});

export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(Dashboard)
);
