import LoginActionNames from "../constants/loginActionNames";
import { Map, List } from "immutable";
import IImmutableLoginReducer from "./interfaces/immutable/iImmutableLoginReducer";
import IAction from "../actions/iAction";
import User from "../models/user";

const initialState = Map({
    isUserLoggedIn: false,
    loading: false,
    user: new User(),
    errors: List()
}) as IImmutableLoginReducer;

const loginReducer = (
    state: IImmutableLoginReducer = initialState,
    action: IAction
) => {
    switch (action.type) {
        case LoginActionNames.LOGIN_WITH_GOOGLE: {
            return state.withMutations(state => {
                state.set("isUserLoggedIn", false);
                state.set("loading", true);
            });
        }
        case LoginActionNames.LOGIN_WITH_GOOGLE_SUCCESS: {
            return state.withMutations(state => {
                state.set('user', action.payload);
                state.set("isUserLoggedIn", true);
                state.set("loading", false);
            });
        }
        case LoginActionNames.LOGIN_WITH_GOOGLE_ERROR: {
            return state.withMutations(state => {
                state.set("isUserLoggedIn", false);
                state.set("loading", false);
                state.update("errors", errors =>
                    errors.push({
                        error: action.payload,
                        time: new Date()
                    })
                );
            });
        }

        case LoginActionNames.LOGOUT: {
            return state.withMutations(state => {
                state.set("isUserLoggedIn", true);
                state.set("loading", true);
            });
        }

        case LoginActionNames.LOGOUT_SUCCESS: {
            return state.withMutations(state => {
                state.set("isUserLoggedIn", false);
                state.set("loading", false);
            });
        }

        case LoginActionNames.LOGOUT_ERROR: {
            return state.withMutations(state => {
                state.set("isUserLoggedIn", true);
                state.set("loading", false);
                state.update("errors", errors =>
                    errors.push({
                        error: action.payload,
                        time: new Date()
                    })
                );
            });
        }

        default: {
            return state;
        }
    }
};

export default loginReducer;
