import ErrorSVG from '../img/error.svg';

export const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        paddingLeft: 10,
        [':active']: {
            backgroundColor: 'rgb(73, 172, 147)',
            color: 'white'
        },
        [':hover']: { color: 'white' },
        ...state.selectProps.inheritedStyles.option
    }),
    menu: (provided: any, state: any) => {
        return {
            ...provided,
            zIndex: 9999,
            textAlign: 'left',
            ...state.selectProps.inheritedStyles.menu
        };
    },
    placeholder: (provided: any, state: any) => {
        return {
            ...provided,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...state.selectProps.inheritedStyles.placeholder
        };
    },
    control: (provided: any, state: any) => {
        const invalidStyles = state.selectProps.touched &&
            state.selectProps.error && {
            borderColor: '#ff5f63!important',
            paddingRight: ' 1.7125rem;',
            backgroundRepeat: 'no-repeat;',
            backgroundPosition: 'center right calc(1.7125rem / 4);',
            backgroundSize: 'calc(1.7125rem / 2) calc(1.7125rem / 2);',
            backgroundImage: `url(${ErrorSVG})`,
            boxShadow: 'none',
            [':hover, :focus, :active']: { boxShadow: '0 0 0 1px #ff5f63' }
        };

        return {
            ...provided,
            ...state.selectProps.inheritedStyles.control,
            ...invalidStyles
        };
    }
};

export const selectTheme = (theme: any) => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        primary25: 'rgb(73, 172, 147)',
        primary: 'rgb(73, 172, 147)'
    }
});
