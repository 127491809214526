import ConfigActionConstants from "../constants/configActionConstants";
import IAction from "./iAction";
import Error from "../models/error";

const fetchConfig = (): IAction => ({ type: ConfigActionConstants.FETCH_CONFIG });

const fetchConfigFulfilled = (): IAction => ({ type: ConfigActionConstants.FETCH_CONFIG_SUCCESS });

const fetchConfigRejected = (error: Error): IAction => ({
    type: ConfigActionConstants.FETCH_CONFIG_ERROR,
    payload: error
});

export default {
    fetchConfig,
    fetchConfigFulfilled,
    fetchConfigRejected
};
