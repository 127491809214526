class ContractType {
  value: string;
  label: string;

  constructor(
    ContractType: {
      value: string;
      label: string;
    } = { value: "", label: "" }
  ) {
    this.value = ContractType.value;
    this.label = ContractType.label;
  }
}
export default ContractType;
