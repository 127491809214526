import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons/faFileSignature';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons/faBusinessTime';
import { faCalculator } from '@fortawesome/free-solid-svg-icons/faCalculator';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons/faCalendarPlus';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons/faMinusSquare';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt';
import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons/faPlaneDeparture';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons/faProjectDiagram';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons/faUmbrellaBeach';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUserTag } from '@fortawesome/free-solid-svg-icons/faUserTag';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faBlogger } from  "@fortawesome/free-brands-svg-icons/faBlogger";
import { faLinkedin } from  "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faFacebook } from  "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from  "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLaptop } from "@fortawesome/free-solid-svg-icons/faLaptop";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons/faMobileAlt";
import { faMobile } from "@fortawesome/free-solid-svg-icons/faMobile";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons/faFileInvoice";
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
    faFileSignature,
    faAngleRight,
    faAt,
    faBan,
    faBell,
    faBook,
    faBusinessTime,
    faCalculator,
    faCalendar,
    faCalendarAlt,
    faCalendarPlus,
    faChartBar,
    faChartLine,
    faCheck,
    faClock,
    faCog,
    faDownload,
    faEdit,
    faEllipsisH,
    faGlobe,
    faHome,
    faInfo,
    faInfoCircle,
    faLock,
    faLongArrowAltRight,
    faMinusSquare,
    faPencilAlt,
    faPhone,
    faPhoneAlt,
    faPlaneDeparture,
    faPlus,
    faPlusSquare,
    faPrint,
    faProjectDiagram,
    faSave,
    faSignOutAlt,
    faSyncAlt,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faUmbrellaBeach,
    faUser,
    faUserEdit,
    faUsers,
    faUserTag,
    faChevronRight,
    faTwitter,
    faBlogger,
    faLinkedin,
    faFacebook,
    faInstagram,
    faLaptop,
    faMobileAlt,
    faMobile,
    faFile,
    faFileInvoice
);

export default FontAwesomeIcon;
