import React, { Component } from "react";
import ComputasLogo from "../img/computas_logo.svg";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import FontAwesomeIcon from "../common/fontAwesomeIcons";
import { Navbar, Nav, Container } from '../common/ReactBootstrapManager';
import { Link } from "@material-ui/core";
import IApplicationState from "../setup/IApplicationState";
import LoginActionCreators from "../actions/loginActions";
import LoginSelectors from "../selectors/loginSelector";

interface INavigationBarProps {
	logout: () => void;
}

class NavigationBar extends Component<INavigationBarProps, any> {

	render() {
		const { logout } = this.props;

		return (
			<Navbar bg="computas-blue" variant="dark" fixed="top" className="navbarBg" expand="sm">
				<Container>
					<Navbar.Brand href="/">
						<span className="d-md-inline">
							<img src={ComputasLogo} alt="logo" className="mt-1" width="150" height="35" title="Computas logo" />
						</span>
					</Navbar.Brand>
					<Nav className="justify-content-end flex-row">
						<div className="text-infoicon logout">
							<FontAwesomeIcon icon="sign-out-alt" className="" size="1x" title="Username" fixedWidth />
							<Link href="#/" className="navbarBg ml-1" color="inherit"  variant="body2" onClick={logout}>
								Sign out
							</Link>
						</div>
					</Nav>
				</Container>
			</Navbar>
		);
	}
}

const mapStoreToProps = (store: IApplicationState) => {
    return {
        isUserLoggedIn: LoginSelectors.isUserLoggedIn(store)
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(LoginActionCreators.logout())
});

export default connect(
    mapStoreToProps,
    mapDispatchToProps
)(NavigationBar);