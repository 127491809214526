import ToasterActionNames from "../constants/toasterActionNames";
import { Map } from "immutable";
import IAction from "../actions/iAction";

const initialState = Map({
    message: false,
    isOpen: false,
    variant: {}
});

const ToasterReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case ToasterActionNames.TOAST_DASH_CLEAR: {
            return state.withMutations(state => {
                state.set("isOpen", false);
            });
        }

        case ToasterActionNames.TOAST_DASH_MESSAGE: {
            return state.withMutations(state => {
                state.set("message", action.payload.message);
                state.set("variant", action.payload.variant);
                state.set("isOpen", true);
            });
        }

        default: {
            return state;
        }
    }
};

export default ToasterReducer;
