import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

interface ILoadingWrapperProps {
  children?: any;
  loading?: boolean;
  classes: any;
  className?: string;
  size?: number;
}

const styles = (theme: Theme) =>
  createStyles({
    progress: {
      color: "#003459"
    },
    paperNotLoaded: {
      padding: theme.spacing(1),
      position: "relative",
      width: theme.spacing(1),
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(1)
      }
    },
    displayNone: {}
  });

class LoadingWrapper extends React.Component<ILoadingWrapperProps> {
  static defaultProps = {
    size: 30
  };
  render() {
    const { children, loading, classes, className, size } = this.props;

    return (
      <>
        {loading && (
          <div className={className || classes.paperNotLoaded}>
            <CircularProgress className={classes.progress} size={size} />
          </div>
        )}
        <div>{children}</div>
      </>
    );
  }
}

export default withStyles(styles)(LoadingWrapper);
