class User {
    familyName: string;
    givenName: string;
    imageUrl: string;
    name: string;

    constructor(user: { family_name: string; given_name: string; picture: string, name: string } = { family_name: "", given_name: "", picture: "", name: "" }) {
        this.familyName = user.family_name;
        this.givenName = user.given_name;
        this.imageUrl = user.picture;
        this.name = user.name;
    }
}

export default User;
