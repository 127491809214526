import IToasterReducer from "../reducers/interfaces/iToasterReducer";

const getToaster = (store: any) =>
    store.get("toaster").toJS() as IToasterReducer;

const toasterSelectors = {
    message: (store: any) => getToaster(store).message,
    open: (store: any) => getToaster(store).isOpen,
    variant: (store: any) => getToaster(store).variant
};

export default toasterSelectors;
