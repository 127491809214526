import { Map } from "immutable";
import base64 from "base-64";

const separator = "#";

const getKey = (key1?: any, key2?: any): string => {
    return `${key1}${separator}${key2}`;
};

const saveState = (state: any) => {
    try {
        const serializedState = JSON.stringify(Map(state));

        localStorage.setItem("state", serializedState);
    } catch (err) {}
};

const getStateByKeys = (keys: any) => {
    try {
        const serializedState: any = localStorage.getItem(getKey(...keys));
        const state = JSON.parse(serializedState);

        return state;
    } catch (err) {
        return null;
    }
};

const saveStateWithKeys = (state: any, keys: any) => {
    try {
        const serializedState = JSON.stringify(state);

        localStorage.setItem(getKey(...keys), serializedState);
    } catch (err) {}
};

const clearStateByKeys = (keys: any) => {
    try {
        localStorage.removeItem(getKey(...keys));
    } catch (err) {}
};

const getItem = (itemKey: string): any => {
    try {
        const item = localStorage.getItem(base64.encode(itemKey));

        if (item) {
            return JSON.parse(base64.decode(item));
        } else return null;
    } catch (err) {
        return null;
    }
};

const saveItem = (item: any, value: any) => {
    try {
        const serializedValue = JSON.stringify(value);

        localStorage.setItem(base64.encode(item), base64.encode(serializedValue));
    } catch (err) {}
};

const clearItem = (key: any) => {
    try {
        localStorage.removeItem(base64.encode(key));
    } catch (err) {}
};

export default {
    saveState,
    getStateByKeys,
	saveStateWithKeys,
    getItem,
    saveItem,
    clearItem,
    clearStateByKeys
};
