import { Map, List } from "immutable";
import IImmutableGenerateAdUsersReducer from "./interfaces/immutable/iImmutableGenerateAdUsersReducer";
import GenerateAdUsersActionNames from "../constants/generateAdUsersActionNames";
import { string } from "prop-types";

const initialState = Map({
    ldapUserInput: string,
    loading: false,
    errors: List()
}) as IImmutableGenerateAdUsersReducer;

const generateAdUsersReducer = (
    state: IImmutableGenerateAdUsersReducer = initialState,
    action: any
) => {
    switch (action.type) {
        case GenerateAdUsersActionNames.GENERATE_AD_USERS: {
            return state.withMutations(state => {
                state.set("loading", true);
            });
        }
        
        case GenerateAdUsersActionNames.GENERATE_AD_USERS_SUCCESS: {
            return state.withMutations(state => {
                state.set("loading", false);
                state.set("ldapUserInput", action.payload);
            });
        }
        
        case GenerateAdUsersActionNames.GENERATE_AD_USERS_ERROR: {
            return state.withMutations(state => {
                state.set("loading", false);
                state.update("errors", errors =>
                    errors.push({
                        error: action.payload,
                        time: new Date()
                    })
                );
            });
        }

        default: {
            return state;
        }
    }
};
export default generateAdUsersReducer;
