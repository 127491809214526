import React from "react";
import Login from "./components/login";
import Toaster from "./common/toaster";
import Config from "./components/config";

interface IRootProps {
  children: JSX.Element;
}

class Root extends React.Component<IRootProps> {
  render() {
    const { children } = this.props;
    return (
      <Config>
        <Login>{children}</Login>
        <Toaster />
      </Config>
    );
  }
}

export default Root;
