import Api from "../lib/axiosApi";
import { getJiraTaskMetadataUrl } from "../constants/urls";
import UrlUtils from "../lib/urlUtils";

const getJiraTaskMetadata = ( issueIdOrKey: string) => {
    const url = `${getJiraTaskMetadataUrl}/${issueIdOrKey}/userdata`;

    return Api.get(UrlUtils.generateUrl(url));
};

export default {
    getJiraTaskMetadata
};







