import ILoginReducer from "../reducers/interfaces/iLoginReducer";

const getLogin = (store: any) => store.get("login").toJS() as ILoginReducer;

const loginSelectors = {
    loading: (store: any) => getLogin(store).loading,
    isUserLoggedIn: (store: any) => getLogin(store).isUserLoggedIn,
    loggedUser: (store: any) => getLogin(store).user
};

export default loginSelectors;
