import toastr from 'toastr';

const options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
    onclick: null,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
};

toastr.options = options;

function info(message, title, options) {
    toastr.info(message, title, options);
}

function error(message, title, options) {
    toastr.error(message, title, options);
}

function warning(message, title, options) {
    toastr.warning(message, title, options);
}

function success(message, title, options) {
    toastr.success(message, title, options);
}

export default {
    info,
    success,
    error,
    warning
};
