import LoginActionCreators from "../actions/loginActions";
import LoginSelectors from "../selectors/loginSelector";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Dispatch } from "redux";
import { createStyles } from "@material-ui/core";
import { connect } from "react-redux";
import AuthorizationConstants from "../constants/authorizationConstants";
import IApplicationState from "../setup/IApplicationState";
import User from "../models/user";
import LoadingWrapper from "../common/loadingWrapper";
import ComputasLogo from "../img/computas_logo.svg";
import GoogleLogo from "../img/icons-google.svg";
import { Row, Col, Form } from "../common/ReactBootstrapManager";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

interface ILoginProps {
  children: JSX.Element;
  classes: any;
  loginWithGoogle: (userData: object, user: User) => any;
  isUserLoggedIn: boolean;
  loading: boolean;
}

const styles = () =>
  createStyles({
    wrapper: {
      backgroundColor: "transparent",
      boxShadow: "none",
      marginLeft: "40%",
      marginBottom: "8px"
    }
  });

class Login extends React.Component<ILoginProps, any> {
  googleResponse = (response: any) => {
    const { loginWithGoogle } = this.props;
    const userData = { id_token: response.credential }; // eslint-disable-line @typescript-eslint/camelcase
    const user = new User(jwt_decode(userData.id_token));
    loginWithGoogle(userData, user);
};

  googleErrorResponse(response: any) {
    throw response;
  }

  render() {
    const { classes, isUserLoggedIn, children, loading } = this.props;

    if (isUserLoggedIn) {
      return (
        <React.Fragment>
          <main>{children}</main>
        </React.Fragment>
      );
    }

    return (
      <div>
        <div className="form-signin">
          <div className="text-center">
            <img
              src={ComputasLogo}
              alt="computas"
              className="mb-5 computasLogo-img"
            />
          </div>
          <Form.Group as={Row}>
            <Col xs={12} sm={12} md={10} lg={4} className="ml-4">
              <LoadingWrapper loading={loading} className={classes.wrapper}>
                <GoogleOAuthProvider
                  clientId={AuthorizationConstants.Google.clientId}
                >
                  <GoogleLogin
                    onSuccess={this.googleResponse}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    type="standard"
                    shape="circle"
                    theme="outline"
                    size="large"
                    logo_alignment="center"
                  />
                </GoogleOAuthProvider>
              </LoadingWrapper>
            </Col>
          </Form.Group>
        </div>
      </div>
    );
  }
}

const mapStoreToProps = (store: IApplicationState) => {
  return {
    isUserLoggedIn: LoginSelectors.isUserLoggedIn(store),
    loading: LoginSelectors.loading(store)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginWithGoogle: (userData: object, user: User) =>
    dispatch(LoginActionCreators.loginWithGoogle(userData, user))
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
