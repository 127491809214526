import { Map, List } from "immutable";
import IImmutableGetIssueIdOrKeyReducer from "./interfaces/immutable/iImmutableGetIssueIdOrKeyReducer";
import GetIssueIdOrKeyActionNames from "../constants/getIssueIdOrKeyActionNames";
import UsernameDto from "../models/usernameDto";

const initialState = Map({
  loading: false,
  jiraMetadata: new UsernameDto(),
  errors: List()
}) as IImmutableGetIssueIdOrKeyReducer;

const getIssueIdOrKeyReducer = (
  state: IImmutableGetIssueIdOrKeyReducer = initialState,
  action: any
) => {
  switch (action.type) {
    case GetIssueIdOrKeyActionNames.GET_ISSUE_OR_KEY: {
      return state.withMutations(state => {
        state.set("loading", true);
      });
    }
    case GetIssueIdOrKeyActionNames.GET_ISSUE_OR_KEY_SUCCESS: {
      return state.withMutations(state => {
        state.set("loading", false);
        state.set("jiraMetadata", new UsernameDto(action.payload));
      });
    }
    case GetIssueIdOrKeyActionNames.GET_ISSUE_OR_KEY_ERROR: {
      return state.withMutations(state => {
        state.set("loading", false);
        state.update("errors", errors =>
          errors.push({
            error: action.payload,
            time: new Date()
          })
        );
      });
    }

    default: {
      return state;
    }
  }
};
export default getIssueIdOrKeyReducer;
