import Api from '../lib/axiosApi';
import UrlUtils from '../lib/urlUtils';
import { getAllAdGroupsUrl, getOfficesUrl } from '../constants/urls';

const getAllAdGroups = () => {
  return Api.get(UrlUtils.generateUrl(getAllAdGroupsUrl));
};

const getOffices = () => {
  return Api.get(UrlUtils.generateUrl(getOfficesUrl));
};

export default {
  getAllAdGroups,
  getOffices,
};
