import { Map } from "immutable";

let config = Map();

class Config {
    static getSetting(key: string): string {
        const value = config.get(key) as string;
        if(!value) {
            throw Error(key);
        }

        return value;
    }

    static setConfig(configuration: any) {
        config = Map(configuration);
    }

    static get getServerUrl(): string {
        return this.getSetting("SERVER-URL");
    }
}

export default Config;
