import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import ToasterActionCreators from "../actions/toasterActions";
import ToasterSelectors from "../selectors/toasterSelector";
import WarningIcon from "@material-ui/icons/Warning";
import amber from "@material-ui/core/colors/amber";
import classNames from "classnames";
import green from "@material-ui/core/colors/green";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

const styles1 = (theme: Theme) =>
    createStyles({
        success: {
            backgroundColor: green[600]
        },
        error: {
            backgroundColor: theme.palette.error.dark
        },
        info: {
            backgroundColor: theme.palette.primary.dark
        },
        warning: {
            backgroundColor: amber[700]
        },
        icon: {
            fontSize: 20
        },
        iconVariant: {
            opacity: 0.9,
            marginRight: theme.spacing(1)
        },
        message: {
            display: "flex",
            alignItems: "center"
        },

        close: {
            marginRight: "0px"
        },
        root: {
            flexWrap: "nowrap"
        }
    });

const variantIcon: any = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

interface IMySnackbarContent {
    classes: any;
    className?: string;
    message: JSX.Element;
    onClose: () => void;
    variant: string;
}

function MySnackbarContent(props: IMySnackbarContent) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className, classes.root)}
            aria-describedby="client-snackbar"
            message={
                <div id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </div>
            }
            {...other}
            action={
                <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>
            }
        />
    );
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles = (theme: Theme) => ({
    margin: {
        margin: theme.spacing(1)
    }
});

interface IToasterProps {
    toasterDashClear: () => any;
    open: boolean;
    message: any;
    variant: string;
}

class Toaster extends React.Component<IToasterProps> {
    render() {
        const { open, message, toasterDashClear, variant } = this.props;

        return (
            <span>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={open}
                    autoHideDuration={2000}
                    onClose={toasterDashClear}
                >
                    <MySnackbarContentWrapper variant={variant} message={message} onClose={toasterDashClear} />
                </Snackbar>
            </span>
        );
    }
}

const mapStoreToProps = (store: any) => {
    return {
        message: ToasterSelectors.message(store),
        open: ToasterSelectors.open(store),
        variant: ToasterSelectors.variant(store)
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toasterDashClear: () => dispatch(ToasterActionCreators.toasterDashClear())
});

export default connect(
    mapStoreToProps,
    mapDispatchToProps
)(withStyles(styles)(Toaster));
