import GetAllAdGroupsActionCreators from '../actions/getAllAdGroupsActions';
import GetAllAdGroupsActionActionNames from '../constants/getAllAdGroupsActionNames';
import GetAllAdGroupsApi from '../api/getAllAdGroupsApi';
import IAction from '../actions/iAction';
import { put, takeLatest, call } from 'redux-saga/effects';

function* getAllAdGroups(action: IAction) {
  const { response, error } = yield call(GetAllAdGroupsApi.getAllAdGroups);

  if (response) {
    yield put(
      GetAllAdGroupsActionCreators.getAllAdGroupsSuccess(response.data)
    );
  } else {
    yield put(GetAllAdGroupsActionCreators.getAllAdGroupsError(error));
  }
}

function* getOffices() {
  const { response, error } = yield call(GetAllAdGroupsApi.getOffices);

  if (response) {
    yield put(GetAllAdGroupsActionCreators.getOfficesSuccess(response.data));
  } else {
    yield put(GetAllAdGroupsActionCreators.getOfficesError(error));
  }
}

export default [
  takeLatest(GetAllAdGroupsActionActionNames.GET_ALL_AD_GROUPS, getAllAdGroups),
  takeLatest(GetAllAdGroupsActionActionNames.GET_OFFICES, getOffices),
];
