import { Map, List } from "immutable";
import IImmutableGenerateUsernamesReducer from "./interfaces/immutable/iImmutableGenerateUsernamesReducer";
import GenerateUsernamesActionNames from "../constants/generateUsernamesActionNames";

const initialState = Map({
    usernames: List(),
    loading: false,
    errors: List()
}) as IImmutableGenerateUsernamesReducer;

const generateUsernamesReducer = (
    state: IImmutableGenerateUsernamesReducer = initialState,
    action: any
) => {
    switch (action.type) {
        case GenerateUsernamesActionNames.GENERATE_USERNAMES: {
            return state.withMutations(state => {
                state.set("loading", true);
            });
        }
        
        case GenerateUsernamesActionNames.GENERATE_USERNAMES_SUCCESS: {
            return state.withMutations(state => {
                state.set("loading", false);
                state.set("usernames", List(action.payload));
            });
        }
        
        case GenerateUsernamesActionNames.GENERATE_USERNAMES_ERROR: {
            return state.withMutations(state => {
                state.set("loading", false);
                state.update("errors", errors =>
                    errors.push({
                        error: action.payload,
                        time: new Date()
                    })
                );
            });
        }

        default: {
            return state;
        }
    }
};
export default generateUsernamesReducer;
