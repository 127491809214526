import GetAllAdGroupsActionNames from '../constants/getAllAdGroupsActionNames';
import IAction from './iAction';
import Error from '../models/error';
import UsernameDto from '../models/usernameDto';
import OfficeDto from '../models/officeInfoDto';
import { List } from 'immutable';

const getAllAdGroups = (): IAction => {
  return {
    type: GetAllAdGroupsActionNames.GET_ALL_AD_GROUPS,
  };
};

const getAllAdGroupsSuccess = (group: UsernameDto): IAction => {
  return {
    type: GetAllAdGroupsActionNames.GET_ALL_AD_GROUPS_SUCCESS,
    payload: group,
  };
};

const getAllAdGroupsError = (error: Error): IAction => {
  return {
    type: GetAllAdGroupsActionNames.GET_ALL_AD_GROUPS_ERROR,
    payload: error,
  };
};

const getOffices = (): IAction => {
  return {
    type: GetAllAdGroupsActionNames.GET_OFFICES,
  };
};

const getOfficesSuccess = (offices: List<OfficeDto>): IAction => {
  return {
    type: GetAllAdGroupsActionNames.GET_OFFICES_SUCCESS,
    payload: offices,
  };
};

const getOfficesError = (error: Error): IAction => {
  return {
    type: GetAllAdGroupsActionNames.GET_OFFICES_ERROR,
    payload: error,
  };
};

export default {
  getAllAdGroups,
  getAllAdGroupsSuccess,
  getAllAdGroupsError,
  getOffices,
  getOfficesSuccess,
  getOfficesError,
};
