interface IApiAuthToken {
    access_token: string;
    expires_in: string;
    token_type: string;
}

class AuthToken {
    accessToken: string;
    expiresIn: null | string;
    tokenType: string;

    constructor() {
        this.accessToken = "";
        this.expiresIn = null;
        this.tokenType = "";
    }

    static mapFromApi(apiModel: IApiAuthToken): AuthToken {
        if (!apiModel) {
            return new AuthToken();
        }

        const authToken = new AuthToken();

        authToken.accessToken = apiModel.access_token;
        authToken.expiresIn = apiModel.expires_in;
        authToken.tokenType = apiModel.token_type;

        return authToken;
    }
}

export default AuthToken;
