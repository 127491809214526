import ConfigReducer from "../reducers/configReducer";
import IAction from "../actions/iAction";
import LoginReducer from "../reducers/loginReducer";
import GenerateUsernamesReducer from "../reducers/generateUsernamesReducer";
import ToasterReducer from "../reducers/toasterReducer";
import { combineReducers } from "redux-immutable";
import GetAllAdGroupsReducer from "../reducers/getAllAdGroupsReducer";
import GenerateAdUsersReducer from "../reducers/generateAdUsersReducer";
import GetIssueIdOrKeyReducer from "../reducers/getIssueIdOrKeyReducer";

const initReducer = () => {
  return combineReducers({
    login: LoginReducer,
    toaster: ToasterReducer,
    config: ConfigReducer,
    usernames: GenerateUsernamesReducer,
    allGroups: GetAllAdGroupsReducer,
    ldapUserInput: GenerateAdUsersReducer,
    jiraMetadata: GetIssueIdOrKeyReducer
  });
};
const appReducer = initReducer();

const reducers = (state: any, action: IAction) => {
  return appReducer(state, action);
};

export default reducers;
