class UsernameDto {
  firstName: string;
  middleName: string;
  lastName: string;
  contractType: string;
  displayName: string;
  userName: string;
  email: string;
  office: string;
  countryCode: string;
  company: string;
  phoneNumber: string;
  street: string;
  city: string;
  zip: string;
  isExternal: boolean;
  adGroups: Array<string>;

  constructor(
    usernameDto: {
      firstName: string;
      middleName: string;
      lastName: string;
      contractType: string;
      displayName: string;
      userName: string;
      email: string;
      office: string;
      countryCode: string;
      company: string;
      phoneNumber: string;
      street: string;
      city: string;
      zip: string;
      isExternal: boolean;
      adGroups: Array<string>;
    } = {
      firstName: "",
      middleName: "",
      lastName: "",
      contractType: "",
      displayName: "",
      userName: "",
      email: "",
      office: "",
      countryCode: "",
      company: "",
      phoneNumber: "",
      street: "",
      city: "",
      zip: "",
      isExternal: false,
      adGroups: []
    }
  ) {
    this.firstName = usernameDto.firstName;
    this.middleName = usernameDto.middleName;
    this.lastName = usernameDto.lastName;
    this.contractType = usernameDto.contractType;
    this.displayName = usernameDto.displayName;
    this.userName = usernameDto.userName;
    this.email = usernameDto.email;
    this.office = usernameDto.office;
    this.countryCode = usernameDto.countryCode;
    this.company = usernameDto.company;
    this.phoneNumber = usernameDto.phoneNumber;
    this.street = usernameDto.street;
    this.city = usernameDto.city;
    this.zip = usernameDto.zip;
    this.adGroups = usernameDto.adGroups || [];
    this.isExternal = usernameDto.isExternal;
  }

  generateEmail(): string {
    const { firstName, lastName, middleName } = this;

    const replaceSpecialChars = (str: string) => {
        return str.toLocaleLowerCase()
            .replace(/æ/g, 'ae')
            .replace(/ø/g, 'oe')
            .replace(/å/g, 'aa');
    };

    const firstNameProcessed = replaceSpecialChars(firstName);
    const lastNameProcessed = replaceSpecialChars(lastName);
    const middleNameProcessed = middleName ? replaceSpecialChars(middleName) : "";

    let email;
    if (middleNameProcessed === "") {
        email = `${firstNameProcessed}.${lastNameProcessed}@computas.com`;
    } else {
        email = `${firstNameProcessed}.${middleNameProcessed}.${lastNameProcessed}@computas.com`;
    }

    return email.replace(/ /g, "");
}

  isAsciiString(param: string) {
    let isAscii = true;
    if (param && !param.match(/^[\x21-\x7E]+$/g)) {
        isAscii = false;
    }
    return isAscii;
  }  

  generateDisplayName(): string {
    const { firstName, lastName, middleName } = this;
    if (
      firstName !== "" &&
      lastName !== "" &&
      (middleName === undefined || middleName === null || middleName === "")
    ) {
      const displayName = `${firstName} ${lastName}`;
      return displayName;
    } else {
      const displayName = `${firstName} ${middleName} ${lastName}`;
      return displayName;
    }
  }

  invalidPhoneNumber(): boolean {
    const { phoneNumber } = this;
    let regex = new RegExp("^(0047|\\+47|47)?[1-9]\\d{7}$");
    if (phoneNumber.match(regex)) {
      return false;
    }
    return true;
  }
}
export default UsernameDto;
