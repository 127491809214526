import React, { Component } from "react";
import NavigationBar from "./NavigationBar";
import { Row, Col } from "../common/ReactBootstrapManager";
import { Container } from "../common/ReactBootstrapManager";
import { GenerateAdUsersTexts } from "../constants/constants";

class ReceiptPage extends Component<any, any> {

	render() {
		return (
			<>
				<Row >
					<Col xs={12} lg={12}>
						<NavigationBar />
					</Col>
				</Row>
				<br />
				<br />

				<Container fluid className="maxWidth pt-5">
					<section>
						<h2 className="headings-title font-weight-bold justify-content-center text-center pt-5 pb-3">
							{GenerateAdUsersTexts.GenerateAdUsersTitle}
						</h2>

						<p className="parag text-center pt-3 pb-3">
							{GenerateAdUsersTexts.GenerateAdUsersParagraph}
						</p>
					</section>
				</Container>
			</>
		);
	}
}

export default (ReceiptPage);
