import { put, takeLatest, call } from "redux-saga/effects";
import HttpResponseCodes from "../browser/httpResponseCodes";
import Token from "../models/token";
import LoginActionCreators from "../actions/loginActions";
import LoginActionNames from "../constants/loginActionNames";
import LoginApi from "../api/loginApi";
import LocalStorageUtils from "../utils/localStorageUtils";
import LocalStorageConstants from "../browser/localStorageConstants";
import IAction from "../actions/iAction";
import toaster from "../lib/toaster";


function* loginWithGoogle(action: IAction) {
  const { userData, user } = action.payload;

  const { response, error } = yield call(LoginApi.loginWithGoogle, userData);

  if (response) {
    if (response.status === HttpResponseCodes.OK) {
      const authToken = Token.mapFromApi(response.data);
      LocalStorageUtils.saveItem(LocalStorageConstants.AUTH_TOKEN, authToken);
      yield put(LoginActionCreators.loginWithGoogleSuccess(user));
      toaster.success('Successfully signed in!');
    }
  } else if (error) {
    yield put(LoginActionCreators.loginWithGoogleError(error));
    toaster.error('The user does not have the required permission!');
  }
}

function* logout() {
  LocalStorageUtils.clearItem(LocalStorageConstants.AUTH_TOKEN);
  yield put(LoginActionCreators.logoutSuccess());
}

export default [
  takeLatest(LoginActionNames.LOGOUT, logout),
  takeLatest(LoginActionNames.LOGIN_WITH_GOOGLE, loginWithGoogle)
];
