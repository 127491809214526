import ConfigActionConstants from "../constants/configActionConstants";
import HttpResponseCodes from "../browser/httpResponseCodes";
import { call, put, takeEvery } from 'redux-saga/effects';
import ConfigActions from "../actions/configActions";
import ConfigApi from "../api/configApi";
import Config from "../lib/config";

function* doFetchConfig() {
    const { response, error } = yield call(ConfigApi.getConfig);

    if (response) {
        if (response.status === HttpResponseCodes.OK) {
            Config.setConfig(response.data);
            yield put(ConfigActions.fetchConfigFulfilled());
        }
    } else if (error) {
        yield put(ConfigActions.fetchConfigRejected(error));
    }
}

export default [takeEvery(ConfigActionConstants.FETCH_CONFIG, doFetchConfig)];
