import { createMuiTheme } from "@material-ui/core/styles";

var primary = "#49bca1";
var secondary = "#003459";

export default createMuiTheme({
    palette: {
        primary: {
            main: primary
        },
        secondary: {
            main: secondary
        }
    },
    typography: {
        h1: {
            color: secondary
        },
        h2: {
            color: secondary
        },
        h3: {
            color: secondary
        },
        h4: {
            color: secondary
        },
        h5: {
            color: secondary
        },
        h6: {
            color: secondary
        },
        subtitle1: {
            color: secondary
        }
    },
    overrides: {
        MuiIconButton: {
            root: {
                marginLeft: -12,
                marginRight: 20,
                color: "white"
            }
        }
    }
});
