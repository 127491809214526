import ToasterActionNames from "../constants/toasterActionNames";
import IAction from "./iAction";

const toasterDashMessage = (message: string, variant: string): IAction => {
  return {
    type: ToasterActionNames.TOAST_DASH_MESSAGE,
    payload: { message, variant }
  };
};

const toasterDashClear = (): IAction => {
  return {
    type: ToasterActionNames.TOAST_DASH_CLEAR
  };
};

export default {
  toasterDashMessage,
  toasterDashClear
};
