import Api from "../lib/axiosApi";
import { loginGoogleApiUrl  } from "../constants/urls";

function loginWithGoogle(userData: any) {
  const data = new FormData();

  data.append("idToken", userData.id_token);

  return Api.post(loginGoogleApiUrl, undefined, { data });
}


export default {
  loginWithGoogle
};
