import keyMirror from "keymirror";

const GetAllAdGroupsActionNames = keyMirror({
    GET_ALL_AD_GROUPS: null,
    GET_ALL_AD_GROUPS_SUCCESS: null,
    GET_ALL_AD_GROUPS_ERROR: null,
    GET_OFFICES: null,
    GET_OFFICES_SUCCESS: null,
    GET_OFFICES_ERROR: null,
});

export default GetAllAdGroupsActionNames;
