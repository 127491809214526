import LoginActionNames from "../constants/loginActionNames";
import Error from "../models/error";
import IAction from "./iAction";
import User from "../models/user";

const loginWithGoogle = (userData: object, user: User): IAction => {
    return {
        type: LoginActionNames.LOGIN_WITH_GOOGLE,
        payload: { userData, user }
    };
};

const loginWithGoogleSuccess = (user: User): IAction => {
    return { 
        type: LoginActionNames.LOGIN_WITH_GOOGLE_SUCCESS, 
        payload: user 
    };
};

const loginWithGoogleError = (error: Error): IAction => {
    return {
        type: LoginActionNames.LOGIN_WITH_GOOGLE_ERROR,
        payload: error
    };
};

const logout = (): IAction => {
    return { type: LoginActionNames.LOGOUT };
};

const logoutSuccess = (): IAction => {
    return { type: LoginActionNames.LOGOUT_SUCCESS };
};

const logoutError = (error: Error): IAction => {
    return {
        type: LoginActionNames.LOGOUT_ERROR,
        payload: error
    };
};

export default {
    loginWithGoogle,
    loginWithGoogleSuccess,
    loginWithGoogleError,
    logout,
    logoutSuccess,
    logoutError
};
