import queryString from "query-string";

class UrlUtils {
  /**
   * Navigate browser to specific url
   * @param {string} url The base url
   * @param {string} parameters URL parameters
   */
  static goto(url: string, parameters?: object) {
    document.location.hash = this.generateInternalUrl(url, parameters);
  }

  static gotoExternal(url: string, parameters?: object) {
    document.location.href = this.generateUrl(url, parameters);
  }

  /**
   * Updates URL query parameters on the current URL
   * @param {object} keyValue Update the given keys with the given values in the current URL
   */
  static updateParameter(keyValue: object) {
    document.location.hash = this.generateUrlWithUpdatedParams(
      document.location.hash,
      keyValue
    );
  }

  static generateUrl(url: string, parameters?: object): string {
    return `${url}${this.stringifyParameters(parameters)}`;
  }

  static generateCompleteUrlWithEscapedHashTag(
    url: string,
    parameters?: object
  ) {
    const hashTagEscaped = "%23";

    return `${document.location.origin}/${hashTagEscaped}/${this.generateUrl(
      url,
      parameters
    )}`;
  }

  static generateUrlWithUpdatedParams(url: string, keyValue: object): string {
    const questionPosition =
      url.indexOf("?") > 0 ? url.indexOf("?") : url.length;
    const baseUrl = url.substring(0, questionPosition);
    const params = url.substring(questionPosition);

    const newParams = {
      ...this.parseParameters(params),
      ...keyValue
    };

    return `${baseUrl}${this.stringifyParameters(newParams)}`;
  }

  static generateInternalUrl(url: string, parameters?: object) {
    return `#/${this.generateUrl(url, parameters)}`;
  }

  static stringifyParameters(parameters: any) {
    const array = [];

    for (const key in parameters) {
      let parameter = parameters[key];

      if (Array.isArray(parameter)) {
        if (parameter.length > 0) {
          parameter.map(param =>
            array.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(param)}`
            )
          );
        }
      } else {
        if (parameter !== undefined && parameter !== "" && parameter !== null) {
          if (parameter instanceof Date) {
            parameter = parameter.toISOString();
          }

          array.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(parameter)}`
          );
        }
      }
    }

    return (array.length === 0 ? "" : "?") + array.join("&");
  }

  static parseParameters(parameters: any) {
    const parsedParameters = Object.create(null);

    if (typeof parameters !== "string") {
      return parsedParameters;
    }

    const parametersWithoutUrl =
      parameters.indexOf("?") === -1
        ? parameters
        : parameters.substring(parameters.indexOf("?") + 1);

    const trimmedParams = parametersWithoutUrl.trim().replace(/^(\?|#|&)/, "");

    if (!trimmedParams) {
      return parsedParameters;
    }

    trimmedParams.split("&").forEach(function(parameter) {
      const parts = parameter.replace(/\+/g, " ").split("=");

      let key: string = parts.shift() as string;
      let value: string | undefined | null =
        parts.length > 0 ? parts.join("=") : undefined;

      key = decodeURIComponent(key);

      value = value === undefined ? null : decodeURIComponent(value);

      if (parsedParameters[key] === undefined) {
        parsedParameters[key] = value;
      } else if (Array.isArray(parsedParameters[key])) {
        parsedParameters[key].push(value);
      } else {
        parsedParameters[key] = [parsedParameters[key], value];
      }
    });

    return parsedParameters;
  }

  static getCurrentUrl(): string {
    return document.location.href;
  }

  static composeRoute(param1: string, param2: string): string {
    return `${param1}/${param2}`;
  }

  static getParams(location: string): any {
    return queryString.parse(location);
  }
}

export default UrlUtils;
