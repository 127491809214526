import GenerateUsernamesActionCreators from "../actions/generateUsernamesActions";
import GenerateUsernamesActionNames from "../constants/generateUsernamesActionNames";
import GenerateUsernamesApi from "../api/generateUsernamesApi";
import IAction from "../actions/iAction";
import { put, takeLatest, call } from "redux-saga/effects";
//import toaster from '../lib/toaster';

function* generateUsernames(action: IAction) {
    const user = action.payload;
    const { response, error } = yield call(GenerateUsernamesApi.generateUsernames, user);

    if (response) {
        yield put(GenerateUsernamesActionCreators.generateUsernamesSuccess(response.data));
        //toaster.success('Successfully user created!');
    } else {
        yield put(GenerateUsernamesActionCreators.generateUsernamesError(error));
    }
}

export default [takeLatest(GenerateUsernamesActionNames.GENERATE_USERNAMES, generateUsernames)];
