import Api from "../lib/axiosApi";

class ConfigApi {
    static getConfig() {
        const url = "config.json";

        return Api.get(url, undefined, { includeBase: true });
    }
}

export default ConfigApi;