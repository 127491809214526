import Creatable from 'react-select/creatable';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { selectTheme, customStyles } from '../constants/selectConfig';

const CreatableSelect = ({ allowCreateWhileLoadingboolean,
    autoFocus,
    className,
    classNamePrefix,
    createOptionPosition,
    defaultInputValue,
    defaultMenuIsOpen,
    defaultValue,
    formatCreateLabel,
    getNewOptionData,
    getOptionLabel,
    getOptionValue,
    inheritedStyles,
    inputValue,
    isClearable,
    isDisabled,
    isMulti,
    isSearchable,
    isValidNewOption,
    menuIsOpen,
    name,
    onBlur,
    onChange,
    onCreateOption,
    onInputChange,
    onMenuClose,
    onMenuOpen,
    options,
    placeholder,
    value }) => {
    const handleChange = value => {
        onChange && onChange(name, value);
    };

    const handleBlur = () => {
        onBlur && onBlur(name, true);
    };

    const selectProps = {
        allowCreateWhileLoadingboolean,
        formatCreateLabel,
        isValidNewOption,
        getNewOptionData,
        onCreateOption,
        createOptionPosition,
        isClearable,
        autoFocus,
        className: className === 'react-select-container' ? className : classNames('react-select-container', className),
        getOptionLabel,
        getOptionValue,
        classNamePrefix,
        defaultInputValue,
        defaultMenuIsOpen,
        defaultValue,
        inheritedStyles,
        inputValue,
        isDisabled,
        isMulti,
        isSearchable,
        menuIsOpen,
        name,
        onChange: handleChange,
        onBlur: handleBlur,
        onInputChange,
        onMenuClose,
        onMenuOpen,
        options: options || [],
        placeholder,
        value,
        styles: customStyles,
        theme: selectTheme
    };

    return <Creatable {...selectProps} />;
};

CreatableSelect.propTypes = {
    allowCreateWhileLoadingboolean: PropTypes.bool,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    classNamePrefix: PropTypes.string,
    createOptionPosition: PropTypes.oneOf(['first', 'last']),
    defaultInputValue: PropTypes.object,
    defaultMenuIsOpen: PropTypes.bool,
    defaultValue: PropTypes.object,
    formatCreateLabel: PropTypes.func,
    getNewOptionData: PropTypes.func,
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    inheritedStyles: PropTypes.object,
    inputValue: PropTypes.string,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isValidNewOption: PropTypes.func,
    menuIsOpen: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onCreateOption: PropTypes.func,
    onInputChange: PropTypes.func,
    onMenuClose: PropTypes.func,
    onMenuOpen: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.array]),//PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.array])
};

CreatableSelect.defaultProps = {
    isClearable: false,
    autoFocus: false,
    className: 'react-select-container',
    classNamePrefix: 'react-select',
    isDisabled: false,
    inheritedStyles: {
        menu: {},
        placeholder: {},
        option: {},
        control: {}
    },
    isMulti: false,
    isSearchable: true,
    placeholder: 'Select...',
    valueKey: 'value',
    labelKey: 'label'
};

export default CreatableSelect;
