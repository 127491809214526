import GetIssueIdOrKeyActionNames from "../constants/getIssueIdOrKeyActionNames";
import IAction from "./iAction";
import Error from "../models/error";
import UsernameDto from "../models/usernameDto";

const getJiraTaskMetadata = (issueIdOrKey: string): IAction => {
  return {
    type: GetIssueIdOrKeyActionNames.GET_ISSUE_OR_KEY,
    payload: issueIdOrKey
  };
};

const getJiraTaskMetadataSuccess = (issueIdOrKey: UsernameDto): IAction => {
  return {
    type: GetIssueIdOrKeyActionNames.GET_ISSUE_OR_KEY_SUCCESS,
    payload: issueIdOrKey
  };
};

const getJiraTaskMetadataError = (error: Error): IAction => {
  return {
    type: GetIssueIdOrKeyActionNames.GET_ISSUE_OR_KEY_ERROR,
    payload: error
  };
};

export default {
  getJiraTaskMetadata,
  getJiraTaskMetadataSuccess,
  getJiraTaskMetadataError
};
