import Api from "../lib/axiosApi";
import { generateAdUsersUrl } from "../constants/urls";

const generateAdUsers = (LDapUser: any) => {
  const data = { ...LDapUser };
  return Api.post(generateAdUsersUrl, undefined, { data });
};

export default {
  generateAdUsers
};
