import GenerateAdUsersActionCreators from "../actions/generateAdUsersActions";
import GenerateAdUsersActionActionNames from "../constants/generateAdUsersActionNames";
import GenerateAdUsersApi from "../api/generateAdUsersApi";
import IAction from "../actions/iAction";
import { put, takeLatest, call } from "redux-saga/effects";
import UrlUtils from "../lib/urlUtils";
import RouteConstants from "../constants/routeConstants";

function* generateAdUsers(action: IAction) {
    const ldapUserInput = action.payload;
    const { response, error } = yield call(GenerateAdUsersApi.generateAdUsers, ldapUserInput);

    if (response) {
        yield put(GenerateAdUsersActionCreators.generateAdUsersSuccess(response.data));
        UrlUtils.goto(RouteConstants.Pages.ReceiptPage);
    } else {
        yield put(GenerateAdUsersActionCreators.generateAdUsersError(error));
    }
}

export default [takeLatest(GenerateAdUsersActionActionNames.GENERATE_AD_USERS, generateAdUsers)];
