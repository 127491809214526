import React, { Component } from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

interface IErrorPageProps {
    classes: any;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "auto",
            display: "block",
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
                width: 600,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: theme.spacing(30)
            },
            margin: "auto"
        },
        paper: {
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
            height: "30vh",
            display: "flex"
        },
        grid: {
            textAlign: "center",
            justifyContent: "center",
            margin: "auto",
            flexDirection: "column",
            alignItems: "center"
        },
        textContent: {
            marginTop: theme.spacing(6)
        }
    });

class ErrorPage extends Component<IErrorPageProps, any> {
    getContent = () => "The page you accessed does not exist.";

    render() {
        const { classes } = this.props;
        return (
                <Paper className={classes.root}>
                    <Grid container className={classes.paper}>
                        <Grid item className={classes.grid}>
                            <Typography gutterBottom variant="h4" component="h4">
                                Oops!
                              </Typography>
                            <Typography className={classes.textContent} gutterBottom variant="h5" component="h5">
                                {this.getContent()}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
        );
    }
}

export default withStyles(styles)(ErrorPage);
