import Api from "../lib/axiosApi";
import { generateUsernamesUrl } from "../constants/urls";

const generateUsernames = (users: any) => {
  const data = { ...users };
  return Api.post(generateUsernamesUrl, undefined, { data });
};

export default {
  generateUsernames
};
