import IApplicationState from "./IApplicationState";
import Reducers from './reducers';
import SagaManager from './sagas';
import createSagaMiddleware, { END } from 'redux-saga';
import { Map } from 'immutable';
import { createStore, applyMiddleware, compose, Store } from 'redux';

type SagaEnhancedStore<S> = Store<S> & {
    close: Function;
    runSaga: Function;
    startAbortableSaga: Function;
};

const sagaMiddleware = createSagaMiddleware();
const devtools =
    (process.env.NODE_ENV === 'development' &&
        typeof window !== 'undefined' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__) ||
    (() => (noop: object): object => noop);

export default function configureStore(initialState: any = Map()): SagaEnhancedStore<IApplicationState> {
    const middlewares = [sagaMiddleware];
    const enhancers = [applyMiddleware(...middlewares), devtools()];
    const store = createStore(Reducers, initialState, compose(...enhancers)) as SagaEnhancedStore<IApplicationState>;

    // Create hook for async sagas
    store.runSaga = sagaMiddleware.run;
    store.startAbortableSaga = (): any => SagaManager.startSaga(sagaMiddleware);
    store.close = (): any => store.dispatch(END);

    if (module.hot) {
        module.hot.accept(
            './reducers',
            (): void => {
                const nextReducers = require('./reducers').default; // eslint-disable-line global-require
                store.replaceReducer(nextReducers);
            },
        );

        module.hot.accept(
            './sagas',
            (): void => {
                SagaManager.cancelSaga(store);
                require('./sagas').default.startSaga(sagaMiddleware); // eslint-disable-line global-require
            },
        );
    }

    return store;
}
