import IGenerateAdUsersReducer from "../reducers/interfaces/iGenerateAdUsersReducer";

const generateAdUsers = (store: any) => store.get("ldapUserInput").toJS() as IGenerateAdUsersReducer;

const generateAdUsersSelectors = {
    loading: (store: any) => generateAdUsers(store).loading,
    ldapUserInput: (store: any) => generateAdUsers(store).ldapUserInput
};

export default generateAdUsersSelectors;

