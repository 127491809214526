import keyMirror from "keymirror";

const LoginActionNames = keyMirror({
    LOGIN_WITH_GOOGLE: null,
    LOGIN_WITH_GOOGLE_SUCCESS: null,
    LOGIN_WITH_GOOGLE_ERROR: null,
    LOGOUT: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_ERROR: null
});

export default LoginActionNames;
