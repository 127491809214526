import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/build/toastr.min.css';
import 'react-app-polyfill/ie11';
import 'core-js';
import './sass/hrManager.scss';
import 'bootstrap';
import AppRouter from "./router";
import React from "react";
import ReactDom from "react-dom";
import configureStore from "./setup/store";
import { Provider } from "react-redux";
import './index.css';

const store = configureStore();

store.startAbortableSaga();
(window as any).Store = store;

const render = (Component: React.StatelessComponent) => {
    ReactDom.render(
        <Provider store={store}>
            <Component />
        </Provider>,
        document.getElementById("root")
    );
};

render(AppRouter);
