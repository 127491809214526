import GenerateUsernamesActionNames from "../constants/generateUsernamesActionNames";
import IAction from "./iAction";
import Error from "../models/error";
import UsernameDto from "../models/usernameDto";

const generateUsernames = (user: UsernameDto): IAction => {
  return {
    type: GenerateUsernamesActionNames.GENERATE_USERNAMES,
    payload: user
  };
};

const generateUsernamesSuccess = (user: UsernameDto): IAction => {
  return {
    type: GenerateUsernamesActionNames.GENERATE_USERNAMES_SUCCESS,
    payload: user
  };
};

const generateUsernamesError = (error: Error): IAction => {
  return {
    type: GenerateUsernamesActionNames.GENERATE_USERNAMES_ERROR,
    payload: error
  };
};

export default {
  generateUsernames,
  generateUsernamesSuccess,
  generateUsernamesError
};
