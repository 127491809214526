import IGetAllAdGroupsReducer from '../reducers/interfaces/iGetAllAdGroupsReducer';

const getAllGroups = (store: any) =>
  store.get('allGroups').toJS() as IGetAllAdGroupsReducer;

const getAllAdUsersSelectors = {
  loading: (store: any) => getAllGroups(store).loading,
  allGroups: (store: any) => getAllGroups(store).allGroups,
  offices: (store: any) => getAllGroups(store).offices,
  loadingOffices: (store: any) => getAllGroups(store).loadingOffices,
};

export default getAllAdUsersSelectors;
