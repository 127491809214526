import GenerateAdUsersActionNames from "../constants/generateAdUsersActionNames";
import IAction from "./iAction";
import Error from "../models/error";
import UsernameDto from "../models/usernameDto";

const generateAdUsers = (LDapUser: UsernameDto): IAction => {
  return {
	type: GenerateAdUsersActionNames.GENERATE_AD_USERS,
	payload: LDapUser
  };
};

const generateAdUsersSuccess = (LDapUser: UsernameDto): IAction => {
  return {
    type: GenerateAdUsersActionNames.GENERATE_AD_USERS_SUCCESS,
    payload: LDapUser
  };
};

const generateAdUsersError = (error: Error): IAction => {
  return {
    type: GenerateAdUsersActionNames.GENERATE_AD_USERS_ERROR,
    payload: error
  };
};

export default {
  generateAdUsers,
  generateAdUsersSuccess,
  generateAdUsersError
};
