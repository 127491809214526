import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { selectTheme, customStyles } from '../constants/selectConfig';

const Select = ({ autoFocus,
    className,
    classNamePrefix,
    defaultInputValue,
    defaultMenuIsOpen,
    defaultValue,
    getOptionLabel,
    getOptionValue,
    inheritedStyles,
    inputValue,
    isClearable,
    isDisabled,
    isMulti,
    isSearchable,
    menuIsOpen,
    name,
    onBlur,
    onChange,
    onInputChange,
    onMenuClose,
    onMenuOpen,
    options,
    placeholder,
    isInvalid,
    value,
    error }) => {
    const selectOptions = options || [];

    const handleChange = value => {
        onChange && onChange(name, value);
    };

    const handleBlur = () => {
        onBlur && onBlur(name, true);
    };

    const selectProps = {
        isClearable,
        autoFocus,
        className: className === 'react-select-container' ? className : classNames('react-select-container', className),
        getOptionLabel,
        getOptionValue,
        classNamePrefix,
        defaultInputValue,
        defaultMenuIsOpen,
        defaultValue,
        inheritedStyles,
        inputValue,
        isDisabled,
        isMulti,
        isSearchable,
        menuIsOpen,
        name,
        onChange: handleChange,
        onBlur: handleBlur,
        onInputChange,
        onMenuClose,
        onMenuOpen,
        options: selectOptions,
        placeholder,
        value,
        styles: customStyles,
        theme: selectTheme,
        error,
        isInvalid
    };

    return (
        <div>
            <ReactSelect {...selectProps} />
            {isInvalid && <div className="invalid-feedback d-block">{error}</div>}
        </div>
    );
};

Select.propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    classNamePrefix: PropTypes.string,
    defaultInputValue: PropTypes.object,
    defaultMenuIsOpen: PropTypes.bool,
    defaultValue: PropTypes.object,
    error: PropTypes.string,
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    inheritedStyles: PropTypes.object,
    inputValue: PropTypes.string,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    labelKey: PropTypes.string,
    menuIsOpen: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    onMenuClose: PropTypes.func,
    onMenuOpen: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.array]), //PropTypes.array,
    placeholder: PropTypes.string,
    isInvalid: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string])
};

Select.defaultProps = {
    isClearable: false,
    autoFocus: false,
    className: 'react-select-container',
    classNamePrefix: 'react-select',
    isDisabled: false,
    inheritedStyles: {
        menu: {},
        placeholder: {},
        option: {},
        control: {}
    },
    isMulti: false,
    isSearchable: true,
    placeholder: 'Select...',
    isInvalid: false,
    error: ''
};

export default Select;
