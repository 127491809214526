import { put, takeLatest, call } from "redux-saga/effects";
import GetIssueIdOrKeyActionCreators from "../actions/getIssueIdOrKeyActions";
import GetIssueIdOrKeyActionNames from "../constants/getIssueIdOrKeyActionNames";
import GetIssueIdOrKeyApi from "../api/getIssueIdOrKeyApi";
import IAction from "../actions/iAction";

function* getJiraTaskMetadata(action: IAction) {
  const issueIdOrKey = action.payload;
  const { response, error } = yield call(
    GetIssueIdOrKeyApi.getJiraTaskMetadata,
    issueIdOrKey
  );

  if (response) {
    yield put(
      GetIssueIdOrKeyActionCreators.getJiraTaskMetadataSuccess(response.data)
    );
  } else {
    yield put(GetIssueIdOrKeyActionCreators.getJiraTaskMetadataError(error));
  }
}

export default [
  takeLatest(GetIssueIdOrKeyActionNames.GET_ISSUE_OR_KEY, getJiraTaskMetadata)
];
